import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import { fetchActorsAndModels } from '../utils/api';
import './SearchBar.css';
import { supabase } from '../supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import ChannelService from "../ChannelService";

function SearchBar() {
  const [actors, setActors] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [coverImages, setCoverImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAdBanner, setShowAdBanner] = useState(true);

  const sampleActorIds = [148, 130, 98, 126, 179, 102, 131, 150, 168, 194];

  const navigate = useNavigate();
  const images = [
    '/img/findout_sample.png',
    '/img/profile_web_sample.png',
    '/img/profile_pdf_sample.png',
    '/img/ai_sample.png'
  ];

  const texts = [
    '- 키워드를 통한 필터 기능 -',
    '- 나만의 웹 프로필 제공 -',
    '- 프로필 PDF파일 자동 완성 기능 -',
    '- AI를 통한 배우 추천 기능 -',
  ];

  useEffect(() => {
    const fetchCoverImages = async () => {
      setIsLoading(true);
      try {
        const fetchedImages = [];

        for (const id of sampleActorIds) {
          const { data: imagesData, error: imagesError } = await supabase
            .from('actor_images')
            .select('actor_id, url')
            .eq('actor_id', id)
            .eq('type', 'cover')
            .maybeSingle();

          if (imagesError) {
            // console.error('Error fetching cover image:', imagesError.message);
            continue;
          }

          if (imagesData) {
            fetchedImages.push(imagesData);
          }
        }

        setCoverImages(fetchedImages);
      } catch (error) {
        // console.error('Error fetching cover images:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoverImages();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchActorsAndModels();

      const processedData = data.map(actor => ({
        ...actor,
        styleKeywords: Array.isArray(actor.styleKeywords) ? actor.styleKeywords : JSON.parse(actor.styleKeywords || '[]'),
        sports: Array.isArray(actor.sports) ? actor.sports : JSON.parse(actor.sports || '[]'),
        dialects: Array.isArray(actor.dialects) ? actor.dialects : JSON.parse(actor.dialects || '[]'),
        talents: Array.isArray(actor.talents) ? actor.talents : JSON.parse(actor.talents || '[]'),
      }));

      setActors(processedData);
    };

    fetchData();
  }, []);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextSlide();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    ChannelService.loadScript();
    
    ChannelService.boot({
      pluginKey: "ed9780ee-efad-4289-b9a5-76ca5988cdf6"
    });
    return () => {
      ChannelService.shutdown();
    };
  }, []);
  const handleCloseBanner = () => {
    setShowAdBanner(false);
    localStorage.setItem('hideAdBanner', 'true'); // 배너 닫힘 상태를 localStorage에 저장
  };

  // 컴포넌트 마운트 시 localStorage에서 배너 상태 확인
  useEffect(() => {
    const hideBanner = localStorage.getItem('hideAdBanner');
    if (hideBanner === 'false') {
      setShowAdBanner(true); // localStorage에 저장된 값이 있으면 배너를 숨김
    }
  }, []);
  return (
    <div className="main-space">
    {showAdBanner && ( // showAdBanner가 true일 때만 배너를 보여줌
        <div className="adBanner" onClick={() => window.open('https://forms.gle/jwfPU2dTf5PqaoYX7', '_blank')}>
          <div style={{ marginLeft: '20px', display:'flex', alignItems:'center'}}>
          <img src="/img/toid-icon.png" alt="logo" style={{width:'20px', height:'20px', marginRight:'10px'}}/>
            토이드 서비스 설명회 신청하기 (11.8 강남)</div>
          <img 
            src="/img/closeblack.png" 
            alt="close" 
            style={{ width: '15px', height: '15px', marginRight: '20px', cursor: 'pointer' }} 
            onClick={(e) => {
              e.stopPropagation(); // 부모 클릭 이벤트 방지
              handleCloseBanner();
            }}
          />
        </div>
      )}

      <div className="main-black-g">
        <h1 className="main-title-am">배우&모델<br></br></h1>
        <div className="main-title">프로필 관리·탐색 플랫폼
          <div className="key-cursor" />
        </div>
        <div className="slider-group">
          <div className="slider-container">
            <div className="slider" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
              {images.map((image, index) => (
                <div className="slide" key={index}>
                  <img className="main-img" src={image} alt={`슬라이드 이미지 ${index}`} />
                  <div className="main-content-text">{texts[index]}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Link to="/register">
          <button className="main-register-button">프로필 등록하기</button>
        </Link>
        <Link to="/actorList">
          <button className="main-actorlist-button">배우&모델 찾기</button>
        </Link>
      </div>
      <div className="main-white">
        <div className="cover-slider">
          <div className="cover-slider-track">
            {[...coverImages, ...coverImages, ...coverImages].map((image, index) => (
              <Link to={`/detail/${image.actor_id}`} key={`${image.actor_id}-${index}`}>
                <div className="cover-image-container">
                  <img
                    className="cover-image"
                    src={image.url}
                    alt={`배우 ${image.actor_id}`}
                    onError={(e) => {
                      // console.error(`Failed to load image: ${image.url}`);
                      e.target.style.display = 'none';
                      e.target.nextSibling.style.display = 'flex';
                    }}
                  />
                  <div className="cover-image-fallback">
                    배우 {image.actor_id}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      

      
      {/* 소개 페이지 */}
      <div className="main-real-white-g"></div>
      <div className="main-real-white">
      <div className="main-actor-model">배우&모델</div>
      <h1 className="main-amt">"작품 준비하기에도 벅차요!"</h1>
        <div className="main-amt-line"></div>
        <h1 className="main-amt-2">"작품과 연습에만 집중하고 싶으신가요?"</h1>
        <h1 className="main-amt-m-2">"작품과 연습에만<br></br>집중하고 싶으신가요?"</h1>

        <div className="m-t-1">등록 한 번으로 편하게 기다리세요!</div>

              <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">1.</span> 구분 <span className="filsu">*필수</span></label>
            </div>
            <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">2.</span> 이름(또는 활동명) <span className="filsu">*필수</span></label>
            </div>
            <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">16.</span> 스타일 키워드 (복수 선택 - 최대 10개) <span className="filsu">*필수</span></label>
            </div>
            <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">17.</span> 구사언어 (복수 선택 가능)</label>
            </div>
            <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">18.</span> 사투리 (복수 선택 가능) </label>
            </div>
            <div className="main-real-white-g"></div>
            </div>
            

            <div className="main-real-white-2">
            <div style={{color:'#000000', fontSize: '30px', fontWeight: '900', lineHeight: '100%',marginBottom:'20px', marginTop:"10px"}}>.<br></br>.<br></br>.</div>
            <div className="main-amt-content">
              나만의 프로필을 <strong style={{fontSize: '28px',color:'#000000'}}>등록</strong>했다면?
            </div>
            
            <div className="m-k-g">
              <div className="m-k-1">귀여운</div>
              <div className="m-k-1c">강아지상</div>
              <div className="m-k-1">평온한</div>
              <div className="m-k-1">부드러운</div>
              <div className="m-k-1c">사랑스러운</div>
            </div>
            <div className="m-k-g">
              <div className="m-k-1c">한국어</div>
              <div className="m-k-1">영어</div>
              <div className="m-k-1">일본어</div>
              <div className="m-k-1">스페인어</div>
            </div>
            <div className="m-k-g">
              <div className="m-k-1">충청도사투리</div>
              <div className="m-k-1">전라도사투리</div>
              <div className="m-k-1c">경상도사투리</div>
              <div className="m-k-1">강원도사투리</div>
            </div>
            <div className="m-k-g">
              <div className="m-k-1">노래</div>
              <div className="m-k-1">피아노</div>
              <div className="m-k-1c">바이올린</div>
            </div>
            <div className="m-k-g">
              <div className="m-k-1c">수영</div>
              <div className="m-k-1">요가</div>
              <div className="m-k-1">골프</div>
              <div className="m-k-1">헬스</div>
            </div>




            <div className="m-am-1">나를 대표하는 <span style={{color: 'rgba(160,88,232,1)'}}>키워드</span>로 웹 프로필 완성!</div>
            <div className="main-amt-img2">
            <img className="main-amt-img-rc" src="/img/profile_web_sample.png" alt="sample" />
            <img className="main-amt-img-rc" src="/img/profile_web_sample2.png" alt="sample" />
            </div>
            <img src="/img/share-big.png" alt="share-big" style={{width: '60px', marginTop: '40px'}}/>
            <img src="/img/main-link-box.png" alt="link-box" style={{width: '350px', marginTop: '10px'}}/>
            <div className="main-amt-content">링크 공유를 통해 쉽게
            <strong style={{fontSize: '24px', color:'#000000', fontWeight:'900'}}> 프로필을 전송</strong>해 보세요!</div>

            <div className="main-amt-content-m">링크 공유를 통해<br></br>
            <strong style={{fontSize: '28px', fontWeight:'900'}}> 쉽게 프로필을 전송</strong>해 보세요!</div>

            <div style={{fontSize:'70px', marginTop:'150px'}}>🤔</div>
            <div className="m-t-2">"왜 프로필을 새로 만들어주나요?"</div>
            <div className="m-am-2">낮은 가독성과<br/>제각각이던 사진</div>
            
            <div className="m-am-3">중요한 정보를 놓치기 쉬운<br/>기존 프로필들</div>

            <img src="/img/fake-profile.png" alt="profile" className="m-am-4-img"/>
            <div className="main-amt-line"></div>
            
            <div className="main-amt-img2" style={{marginTop: '50px'}}>
            <img className="main-amt-img-rc2" src="/img/profile_pdf_sample2.png" alt="sample" />
            <img className="main-amt-img-rc2" src="/img/profile_pdf_sample.png" alt="sample" />
            </div>

            <div style={{color:'#555555', fontSize:'28px', fontWeight: '800', marginTop: '50px'}}>필요한 정보만 깔끔하게 담아</div>
            <div style={{color:'#222222', fontSize:'35px', fontWeight: '800', marginTop: '10px'}}>가독성<span style={{color:'rgba(160,88,232,1)'}}> UP!</span></div>

            <img src="/img/main-keyw.png" alt="pdf-kw" className="m-am-5-img"/>

            <div style={{color:'#555555', fontSize:'28px', fontWeight: '800', marginTop: '50px'}}>내가 직접 택한</div>
            <div style={{color:'#222222', fontSize:'35px', fontWeight: '800', marginTop: '10px'}}>특색있는<span style={{color:'rgba(160,88,232,1)'}}> 키워드</span></div>

            <img src="/img/main-pdf.png" alt="pdf-kw" className="m-am-6-img"/>
            <div style={{color:'#555555', fontSize:'28px', fontWeight: '800', marginTop: '50px'}}>프로필 사진과 함께</div>
            <div style={{color:'#222222', fontSize:'35px', fontWeight: '800', marginTop: '10px'}}>내가 출연한<span style={{color:'rgba(160,88,232,1)'}}> 스틸컷</span>까지!</div>
            
            <img src="/img/pdf-big.png" alt="share-big" style={{width: '60px', marginTop: '100px'}}/>
            <div className="m-am-7">
                PDF 버튼을 눌러서 <strong style={{fontSize: '28px', fontWeight:'900'}}> 출력</strong>해보세요!
              </div>

              <div className="m-am-8">보다 효과적인 프로필 제작</div>
              <div className="m-am-10">
              <img src="/img/ToidLogo.png" alt="logo" className="m-am-9-img"/>
                와 바로 함께해 보세요!</div>

                <div className="m-box-s-group">
              <div className="m-box-s">
                <div className="m-box-s1"><img src="/img/free-icon-font-lock.png" alt="icon" style={{width:'40px', height:'40px'}}/></div>
                <div className="m-box-s2">개인정보 보호</div>
                <div className="m-box-s3">개인정보 노출 없이<br/>캐스팅 가능</div>
              </div>

              <div className="m-box-s">
                <div className="m-box-s1"><img src="/img/free-icon-font-time-add.png" alt="icon" style={{width:'40px', height:'40px'}}/></div>
                <div className="m-box-s2">시간 절약</div>
                <div className="m-box-s3">프로필 등록 시,<br/>바로 PDF 변환 가능</div>
              </div>

              <div className="m-box-s">
                <div className="m-box-s1"><img src="/img/free-icon-font-users.png" alt="icon" style={{width:'40px', height:'40px'}}/></div>
                <div className="m-box-s2">언제나 누구든지</div>
                <div className="m-box-s3">다른 제한 없이<br/>프로필 등록 가능</div>
              </div>

            </div>
            </div>
            <div className="main-real-white-g-re"></div>
            <div className="main-real-purple">

              <div className="main-castingdirector">캐스팅 담당자</div>
              <h1 className="m-cd">"작품에 알맞는 배우 찾기가 너무 어려워요!"</h1>
              <h1 className="m-cd-m">"작품에 알맞는 배우 찾기가<br/>너무 어려워요!"</h1>
              <div className="main-cd-line"></div>
              <h1 className="m-cd-1">빠르면서 쉽게<br/>작품에 맞는 배우를 찾고 싶으신가요?</h1>

            <div className="m-cd-2">원하는 조건만<span style={{fontSize:'34px', fontWeight:'800'}}> 입력</span>해 주세요!</div>

            <img src="/img/main-filter.png" alt="filter" className="m-cd-3-img"/>

            <div style={{color:'#ececec', fontSize: '30px', fontWeight: '900', lineHeight: '100%',marginBottom:'20px', marginTop:"10px"}}>.<br></br>.<br></br>.</div>
            
            <div className="m-cd-4">원하는 배우를 찾았다면?</div>
            <div className="m-cd-5">배우 프로필 하단 섭외 요청서 작성후</div>
            <img src="/img/main-cast-message.png" alt="filter" className="m-cd-6-img"/>

            <img src="/img/main-cast-button.png" alt="filter" className="m-cd-7-img"/>

            <div className="m-cd-8">섭외 요청하기 클릭!</div>

            <div className="m-cd-9"></div>
            </div>

            <div className="main-real-black">
            <div>
            <div className="m-dp-1"></div>
            </div>

            
            

            <div className="main-staff-i">감독&PD</div>
            <h1 className="main-staff" >"작품 구인도 이렇게 쉽게 하면 좋겠어요!"</h1>
            <h1 className="main-staff-m">"작품 구인도<br></br>이렇게 쉽게 하면 좋겠어요!"</h1>
            <div className="main-staff-line" style={{marginTop:'40px'}}></div>

            <div>
              <div className="m-d-g">
                <div>
                <img src="/img/main-ot.png" alt="filter" style={{width:'350px', borderRadius:'15px', filter:'blur(2px)'}}/>
                </div>
                <div className="m-d-g-2">
                <span>낮은 신뢰성과 전달력</span>
                <span style={{opacity:'80%'}}>쉽게 노출되는 개인정보</span>
                <span style={{opacity:'50%'}}>한 눈에 보기 힘든 지원자</span>
                <span style={{opacity:'30%'}}>개별로 답신해야하는 번거로움</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
                <span>별거 아닌듯 하여도</span>
                <span>지속적으로 느끼는 불편함</span>
                <sapn className="m-dp-2"><img src="/img/ToidLogow.png" alt="logo" style={{width:'110px', marginRight:'5px'}}/>로 쉽게 해결!</sapn>
                </div>
              </div>
            </div>

            <div className="m-dp-3">
              작품구인도 <img src="/img/ToidLogow.png" alt="logo" className="m-dp-4-img"/>
              에서 한 번에!
            </div>

            <div className="m-d-1">
              <div>
              <img src="/img/main-hiring.png" alt="hiring" className="m-dp-5-img"/>
              </div>
              <div>
              <img src="/img/main-hiring-t.png" alt="hiring" className="m-dp-5-img"/>
              </div>
            </div>
            
            <div className="m-d-2">배우&모델에게 필요한 내용만 <span style={{color:'rgb(178, 119, 237)'}}>깔끔하게!</span></div>
            <div className="m-d-2">핵심 정보로 <span style={{color:'rgb(178, 119, 237)'}}>전달력 UP!</span></div>

            <img src="/img/main-hiring-dash.png" alt="logo" className="m-dp-6-img"/>
            <div className="m-d-2"><span style={{color:'rgb(178, 119, 237)'}}>한 눈에 확인 가능한 </span>지원자 리스트</div>

            <img src="/img/main-actor-dash.png" alt="logo" className="m-dp-6-img"/>
            <div className="m-d-2">배우&모델 <span style={{color:'rgb(178, 119, 237)'}}>지원 결과</span>공유까지!</div>


            <div className="m-d-3">작품 구인에서 느낀 불편함을<br/>이젠 해소할 타이밍!</div>


            <div className="m-d-4">THE 효율적인 플랫폼</div>
            <div className="m-d-5">
            <img src="/img/ToidLogow.png" alt="logo" className='m-dp-7-img'/>
              와 지금 바로 함께해 보세요!
              </div>

              <div className="m-box-s-group">
              <div className="m-box-s" style={{backgroundColor:'#ffffff'}}>
                <div className="m-box-s1"><img src="/img/free-icon-font-lock.png" alt="icon" style={{width:'40px', height:'40px'}}/></div>
                <div className="m-box-s2">개인정보 보호</div>
                <div className="m-box-s3">개인정보 노출 없이<br/>작품 구인 가능</div>
              </div>

              <div className="m-box-s" style={{backgroundColor:'#ffffff'}}>
                <div className="m-box-s1"><img src="/img/free-icon-font-time-add.png" alt="icon" style={{width:'40px', height:'40px'}}/></div>
                <div className="m-box-s2">시간 절약</div>
                <div className="m-box-s3">한 곳에서 지원자 확인과<br/>지원 결과 공유까지<br/>모두 가능</div>
              </div>

              <div className="m-box-s" style={{backgroundColor:'#ffffff'}}>
                <div className="m-box-s1"><img src="/img/free-icon-font-users.png" alt="icon" style={{width:'40px', height:'40px'}}/></div>
                <div className="m-box-s2">쉽고 빠른 배우 찾기</div>
                <div className="m-box-s3">필요한 조건만<br/>간결하게 입력 후,<br/>배우 찾기 가능</div>
              </div>

            </div>

            </div>

            <div style={{width:'100%', height: '10px', backgroundColor:'#ffffff'}}></div>
            <div className="main-real-color">

            <div className="m-d-5">지금 바로
            <img src="/img/ToidLogow.png" alt="logo" style={{width:'110px', marginRight:'5px'}}/>
              를 이용해 보세요!
              </div>

              <div className="m-d-6"style={{color: 'rgb(160, 88, 232)', width:'300px',height:'50px', borderRadius:'100px', backgroundColor:'#ffffff', display:'flex', alignItems:'center', justifyContent:'center', fontSize:'20px', fontWeight:'700', marginTop:'40px'}}
              onClick={() => navigate('/actorList')}>
                배우&모델 찾기 이동
              </div>

            </div>
            <div style={{width:'100%', height: '10px', backgroundColor:'#ffffff'}}></div>


      
     

      <div className="footer">
        <div className="footer-bar"></div>
        <div style={{display:'flex', marginBottom:'30px'}}>
        <a href="https://instagram.com/toid.io" target="_blank" rel="noopener noreferrer">
        <img 
          src="/img/instagramicon.jpg" 
          style={{width: '40px', height:'40px', borderRadius:'100px'}} 
          alt="insta" 
        />
        </a>
        </div>
        토이드(TOID) | 충남 아산시 순천향로 22 미디어랩스관 702호 <br></br>
        대표 : 양승철 | 문의 : toidfilm@gmail.com
        <p>© 2024. TOID. all rights reserved.</p>
      </div>
    </div>
  );
}

export default SearchBar;
